//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


//
// webpack entry file
//


//
// Load assets
//
import '../css/styles.scss'
import 'FRONTEND/images/favicon.ico'

import { createApp, h }             from 'vue'
import { createPinia }              from 'pinia'

import Buefy                        from '@ntohq/buefy-next'

import dayjs                        from 'dayjs'
import AdvancedFormat               from 'dayjs/plugin/advancedFormat.js'
import isSameOrAfter                from 'dayjs/plugin/isSameOrAfter.js'
import isSameOrBefore               from 'dayjs/plugin/isSameOrBefore.js'
import LocaleData                   from 'dayjs/plugin/localeData.js'
import LocalizedFormat              from 'dayjs/plugin/localizedFormat.js'
import utc                          from 'dayjs/plugin/utc.js'

import smoothscroll                 from 'smoothscroll-polyfill'
import * as zenscroll               from 'zenscroll'

import Toast, { useToast }          from 'vue-toastification'

import VueForceNextTick             from 'vue-force-next-tick'

import * as appConfig               from './app.config.js'

import router                       from './router/index.js'

import socket                       from './socket.js'
import { bindSocketHooks }          from './socket.js'

import App                          from '../App.vue'

import * as appHelpers              from './helpers.js'


dayjs.extend(AdvancedFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(LocaleData)
dayjs.extend(LocalizedFormat)
dayjs.extend(utc)

export { dayjs }

smoothscroll.polyfill()

const zenscrollDefaultDuration = 400 // ms
const zenscrollEdgeOffset = 25 // px
zenscroll.setup(zenscrollDefaultDuration, zenscrollEdgeOffset)


//
// Vue setup
//
export const app = createApp(App)


app.use(createPinia())
app.use(router)
app.use(Buefy)
app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 5,
    newestOnTop: true,
    timeout: appConfig.DEFAULT_TOAST_DURATION,
    position: 'bottom-right',
    hideProgressBar: true
})
app.use(VueForceNextTick)

bindSocketHooks()


app.config.globalProperties.$socket = socket
app.config.globalProperties.$scroll = zenscroll
app.config.globalProperties.$dayjs  = dayjs
app.config.globalProperties.$toast  = useToast()


app.config.globalProperties.$filters = {
    toCurrency(value, digits = 2) {
        return appHelpers.toCurrency(value, digits)
    }
}

app.mount('#app')
