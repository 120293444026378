import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "map-view-container" }
const _hoisted_2 = { class: "map-search" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_google_map = _resolveComponent("google-map")
  const _component_columns_quicksearch = _resolveComponent("columns-quicksearch")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.googleMapsLoaded)
      ? (_openBlock(), _createBlock(_component_google_map, {
          key: "mobile_mapview_map",
          ref: "googleMapMobileMapView",
          "map-id": "map-mobile-map-view",
          "min-zoom": 2,
          "use-clustering": $data.userSettingMapUseClustering,
          "clustering-max-zoom": $data.userSettingMapClusteringMaxZoom,
          fullscreenControl: false,
          "mobile-map-view": true
        }, null, 8 /* PROPS */, ["use-clustering", "clustering-max-zoom"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_columns_quicksearch, {
        column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") },
        "mobile-map-view-search": true
      }, null, 8 /* PROPS */, ["column"])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.appLoading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}