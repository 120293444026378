//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'


export const useSearchStore = defineStore('search', {
    state: () => {
        return {
            searchStr: '',

            searchLoading: false,

            searchInputTimer: null,
            searchInputDebounce: 250, // ms
        }
    },


    getters: {
        results() {
            return []
        }
    },


    actions: {
        debounceTextInput() {
            // Delayed text input event send
            return (value) => {
                const newSearchStr = value.length > 0 ? value.trim() : ''

                if(!this.searchStr && !newSearchStr) return

                this.searchLoading = true
                clearTimeout(this.searchInputTimer)
                this.searchInputTimer = setTimeout(() => {
                    this.searchStr = value
                    this.searchLoading = false
                }, this.searchInputDebounce)
            }
        },
    }

})
