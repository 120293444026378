import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-top" }
const _hoisted_2 = { class: "mobile-top-left" }
const _hoisted_3 = { class: "mobile-top-center" }
const _hoisted_4 = { class: "product-selector" }
const _hoisted_5 = { class: "dropdown-product-name" }
const _hoisted_6 = { class: "dropdown-product-name" }
const _hoisted_7 = {
  key: 1,
  class: "pagetitle"
}
const _hoisted_8 = {
  key: 2,
  class: "pagetitle"
}
const _hoisted_9 = {
  key: 3,
  class: "pagetitle"
}
const _hoisted_10 = { class: "mobile-top-right" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.rootStore.currentPage === "search")
      ? (_openBlock(), _createBlock(_component_b_field, {
          key: 0,
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_b_input, {
              placeholder: "Search Rudolf",
              type: "text",
              icon: "magnify",
              modelValue: _ctx.searchStr,
              onInput: _cache[0] || (_cache[0] = (event) => { _ctx.debounceTextInput(event.target.value) }),
              "icon-right": _ctx.searchStr ? "close-circle" : "",
              "icon-right-clickable": true,
              onIconRightClick: _cache[1] || (_cache[1] = $event => (_ctx.searchStr = "")),
              loading: _ctx.searchLoading
            }, null, 8 /* PROPS */, ["modelValue", "icon-right", "loading"])
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass(["search is-clickable", { "active": _ctx.rootStore.currentPage === "search" }]),
              onClick: _cache[2] || (_cache[2] = $event => ($options.goToPage("search")))
            }, null, 2 /* CLASS */), [
              [_vShow, [ "activities", "map-view", "manage-leads-mobile", "lead-details", "account" ].includes(_ctx.rootStore.currentPage)]
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            ([ "map-view", "manage-leads", "manage-leads-mobile" ].includes(_ctx.rootStore.currentPage))
              ? (_openBlock(), _createBlock(_component_b_dropdown, {
                  key: 0,
                  modelValue: $options.filteredProductId,
                  "aria-role": "menu",
                  "mobile-modal": false,
                  scrollable: true,
                  "max-height": "430px",
                  onChange: _cache[3] || (_cache[3] = (productId) => $options.filterLeadsByProduct(productId))
                }, {
                  trigger: _withCtx(({ active }) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("h2", null, _toDisplayString($options.pageTitleFull), 1 /* TEXT */),
                      _createVNode(_component_b_icon, {
                        icon: active ? 'chevron-up' : 'chevron-down',
                        "custom-size": "mdi-24px"
                      }, null, 8 /* PROPS */, ["icon"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_b_dropdown_item, {
                      value: null,
                      "aria-role": "menuitem"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.l('generic/allproducts')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.productsSorted, (product) => {
                      return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                        key: product._id,
                        value: product._id,
                        "aria-role": "menuitem"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(product.name), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"]))
              : (_ctx.rootStore.currentPage === "lead-details")
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.rootStore.openedLeadName || 'Lead details'), 1 /* TEXT */)
                  ]))
                : (_ctx.rootStore.currentPage === "account")
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.l('account/pagetitle')), 1 /* TEXT */)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[5] || (_cache[5] = [
                      _createElementVNode("h2", null, "The Rudolf", -1 /* HOISTED */)
                    ])))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass(["account is-clickable", { "active": _ctx.rootStore.currentPage === "account" }]),
              onClick: _cache[4] || (_cache[4] = $event => ($options.goToPage("account")))
            }, null, 2 /* CLASS */), [
              [_vShow, [ "activities", "map-view", "manage-leads-mobile", "lead-details", "account" ].includes(_ctx.rootStore.currentPage)]
            ])
          ])
        ], 64 /* STABLE_FRAGMENT */))
  ]))
}