import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "topnav-buttons" }
const _hoisted_2 = { class: "advanced-filter-tool" }
const _hoisted_3 = { class: "rnotifications" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_advanced_filter_tool = _resolveComponent("advanced-filter-tool")
  const _component_notifications = _resolveComponent("notifications")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_advanced_filter_tool)
    ], 512 /* NEED_PATCH */), [
      [_vShow, ["manage-leads", "overview"].includes(_ctx.rootStore.currentPage)]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_notifications)
    ])
  ]))
}