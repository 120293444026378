//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'

import languageList                 from '../language_list.js'

import { dayjs }                    from '../app.js'


const api = {
    'setAsActive':          [ 'POST',        '/localizations/set-as-active' ],
    'saveLocalization':     [ 'PUT',         '/localizations' ],
    'import':               [ 'PUT',         '/localizations/import' ],
}


const localizations     = []
const localizationsMap  = new Map()


export const useLocalizationsStore = defineStore('localizations', {
    state: () => {
        return {
            ...crudFactory.states(),

            // Fields and default english translations
            fields: {
                //
                // GENERIC
                //
                'generic/cancel':               { default: 'Cancel', details: 'Button text etc.'},
                'generic/discard':              { default: 'Discard', details: 'Button text etc.'},
                'generic/save-n-exit':          { default: 'Save & exit', details: 'Button text etc.'},
                'generic/close':                { default: 'Close', details: 'Button text etc.'},
                'generic/go-back':              { default: 'Go back', details: 'Button text etc.'},
                'generic/next':                 { default: 'Next', details: 'Button text etc.' },
                'generic/save':                 { default: 'Save', details: 'Button text etc.' },
                'generic/stay':                 { default: 'Stay', details: 'Button text etc.' },
                'generic/continue':             { default: 'Continue', details: 'Button text etc.' },
                'generic/delete':               { default: 'Delete', details: 'Button text etc.' },
                'generic/export':               { default: 'Export', details: 'Button text etc.' },
                'generic/confirm':              { default: 'Confirm', details: 'Button text etc.' },
                'generic/add':                  { default: 'Add', details: 'Button text etc.' },
                'generic/create':               { default: 'Create', details: 'Button text etc.' },
                'generic/proceed':              { default: 'Proceed', details: 'Button text etc.' },
                'generic/edit':                 { default: 'Edit', details: 'Button text etc.' },
                'generic/send':                 { default: 'Send', details: 'Button text etc.' },
                'generic/lead':                 { default: 'Lead' },
                'generic/email':                { default: 'Email' },
                'generic/password':             { default: 'Password' },
                'generic/hello':                { default: 'Hello', details: 'Page top left corner "Hello <username>"' },
                'generic/user':                 { default: 'User' },
                'generic/month':                { default: 'Month' },
                'generic/product':              { default: 'Product' },
                'generic/allproducts':          { default: 'All products' },
                'generic/today':                { default: 'Today', details: 'E.g. in dates: "Today, 1st January 2024"' },
                'generic/tomorrow':             { default: 'Tomorrow', details: 'E.g. in dates: "Tomorrow, 2nd January 2024"' },
                'generic/more':                 { default: 'More', details: 'E.g. tab header to open "more" filters' },
                'generic/min':                  { default: 'Min' },
                'generic/max':                  { default: 'Max' },
                'generic/reset':                { default: 'Reset' },
                'generic/error':                { default: 'Error' },
                'generic/type':                 { default: 'Type' },
                'generic/start':                { default: 'Start' },
                'generic/end':                  { default: 'End' },
                'generic/select':               { default: 'Select' },
                'generic/answer':               { default: 'Answer' },
                'generic/search':               { default: 'Search' },
                'generic/url':                  { default: 'URL', details: 'As in: URL link' },
                'generic/text':                 { default: 'Text' },
                'generic/add-a-tag':            { default: 'Add a tag' },
                'generic/name':                 { default: 'Name', details: 'As in: user name, status name, product name, etc.' },
                'generic/role':                 { default: 'Role', details: 'As in: user role' },
                'generic/contact':              { default: 'Contact' },
                'generic/title':                { default: 'Title', details: 'User title, (Mr/Ms/Mrs)' },
                'generic/default':              { default: 'Default' },
                'generic/first-name':           { default: 'First name' },
                'generic/last-name':            { default: 'Last name' },
                'generic/phone-number':         { default: 'Phone number' },
                'generic/company':              { default: 'Company' },
                'generic/no-leads-selected':    { default: 'No leads selected', details: 'Notification message' },
                'generic/assign':               { default: 'Assign' },
                'generic/assigned-to':          { default: 'Assigned to' },
                'generic/unassigned':           { default: 'Unassigned', details: 'When product is assigned to nobody' },
                'generic/server-error':         {
                    default: 'Server error, please try again or contact Rudolf support if the problem persists',
                    details: 'Generic error message if backend fails for whatever reason', type: 'textarea'
                },
                'generic/google-maps-failed':   { default: 'Failed to initialize Google Maps' },
                'generic/access-level-changed': {
                    default: 'Leads access updated by admin, app has been reloaded', type: 'textarea',
                    details: 'Notification that comes when logged-in and admin changes user level or visibily rights'
                },
                'generic/logout-user-deleted': {
                    default: 'User has been deleted by admin, you have been logged out.', type: 'textarea',
                    details: 'Notification that comes if admin deletes your user while logged-in'
                },


                //
                // EMAILS
                //
                'emails/new-user-set-password-email-title': {
                    default: 'Welcome to The Rudolf, ##1 - Set your password',
                    details: 'New user welcome email subject, ##1 = User first name'
                },
                'emails/new-user-set-password-email-body': {
                    default: 'A new user account with this email has been created in The Rudolf -app at ##1\n\nGo to the link below to set your password for logging in:\n\n##2\n\n- The Rudolf team',
                    details: 'New user welcome email body text, ##1 = Rudolf app URL link, ##2 = Full URL link to setting new user password',
                    type: 'textarea'
                },
                'emails/reset-password-email-title': {
                    default: 'Reset password - The Rudolf',
                    details: 'Reset password email subject'
                },
                'emails/reset-password-email-body': {
                    default: 'Your verification code for password reset: ##1\n\n- The Rudolf team',
                    details: 'Reset password email body, ##1 = verification code',
                    type: 'textarea'
                },
                'emails/due-date-1week-reminder-email-title': {
                    default: 'REMINDER: Due date on ##1 / ##2 in 1 week',
                    details: 'Due date 1 week reminder email title text, ##1 = lead name, ##2 = product name',
                    type: 'textarea'
                },
                'emails/due-date-1week-reminder-email-body': {
                    default: 'REMINDER: You have an upcoming due date in 1 week on ##1 / ##2\n\nDue date: ##3\n\n- The Rudolf team',
                    details: 'Due date 1 week reminder email body text, ##1 = lead name, ##2 = product name, ##3 = due date',
                    type: 'textarea'
                },


                //
                // LOGIN
                //
                'login/signin-welcome': {
                    default: 'Sign-In!',
                    details: 'Greeting under Rudolf logo'
                },
                'login/user-pass-mismatch': {
                    default: 'Username and password don\'t match!', type: 'textarea'
                },
                'login/forgot-password':        { default: 'Forgot password?' },
                'login/signin-button':          { default: 'Sign in', details: 'Sign in button' },
                'login/logged-in-as':           { default: 'Logged in as' },
                'login/password-reset-step1-title': { default: 'Password reset', details: 'Step 1' },
                'login/password-reset-step1-text': {
                    default: 'Please enter the email address linked to the account to receive password reset confirmation link',
                    type: 'textarea', details: 'Step 1'
                },
                'login/password-reset-step2-1': {
                    default: 'Please enter the verification code sent to your email.',
                    type: 'textarea', details: 'Step 2, verification code'
                },
                'login/password-reset-step2-2': {
                    default: 'If you did not receive an email from us, double check your email in the previous step',
                    type: 'textarea', details: 'Step 2, verification code'
                },
                'login/password-reset-step2-incorrect-code': {
                    default: 'The code you have entered is incorrect.',
                    type: 'textarea', details: 'Step 2, verification code'
                },
                'login/password-reset-step2-please-retry':  { default: 'Please re-try.', details: 'Step 2, verification code' },
                'login/password-reset-step2-resend-code':   { default: 'Resend code', details: 'Step 2, verification code' },
                'login/password-reset-step2-code-resent':   { default: 'Code resent to ##1', details: 'Step 2, verification code. ##1 = email address code was resent to' },
                'login/password-reset-step3-title':         { default: 'Reset password', details: 'Step 3, setting new password (forgot password reset step)' },
                'login/password-reset-step3-new-user-pwd-title': { default: 'Set password', details: 'Step 3, setting new password (new user 1st time password)' },
                'login/password-reset-step3-create-new':    { default: 'Create a new password to log-in', details: 'Step 3, setting new password' },
                'login/password-reset-step3-new-pwd':       { default: 'New password', details: 'Step 3, setting new password' },
                'login/password-reset-step3-new-pwd-again': { default: 'New password (again)', details: 'Step 3, setting new password' },
                'login/password-reset-step3-pwd-rules':     { default: 'Password must contain atleast 7 letters, 2 numbers and a special character.', details: 'Step 3, setting new password' },
                'login/password-reset-step3-pwd-mismatch':  { default: 'Password mismatch', details: 'Step 3, setting new password' },


                //
                // SIDEBAR
                //
                'sidebar/overview':               { default: 'Overview' },
                'sidebar/manageleads':            { default: 'Leads' },
                'sidebar/activities':             { default: 'Activities' },
                'sidebar/setup':                  { default: 'Set up' },
                'sidebar/setup-products':         { default: 'Products' },
                'sidebar/setup-users':            { default: 'Subscription & User Role' },
                'sidebar/setup-users-short': {
                    default: 'Subs. & User Role',
                    details: 'Shorter version of "users" page link in sidebar for compact layout',
                },
                'sidebar/setup-data':             { default: 'Data' },
                'sidebar/setup-labels':           { default: 'Labels' },
                'sidebar/setup-localization':     { default: 'Localization' },
                'sidebar/account':                { default: 'Account' },


                //
                // GOOGLE MAP
                //
                'googlemap/map-settings':         { default: 'Map settings' },
                'googlemap/use-clustering':       { default: 'Use clustering for lead markers' },
                'googlemap/use-donuts':           { default: 'Use "donut" chart icons for clusters when filtering by one product' },
                'googlemap/max-zoom':             { default: 'Maximum zoom level at which clustering should happen' },


                //
                // OVERVIEW PAGE
                //
                'overview/pagetitle':             { default: 'Overview', details: 'Overview page title when no product selected or multiple products filtered' },
                'overview/pagetitle-product':     { default: 'Overview of ##1', details: 'Overview page title when one product selected / filtered, ##1 = Product name' },
                'overview/allusers':              { default: 'All users' },
                'overview/personal-target':       { default: 'Personal target', details: 'Header on page' },
                'overview/personal-sales-target': { default: 'Personal sales target', details: 'Header inside cog-wheel dropdown' },
                'overview/select-user-text': {
                    default: 'Select a user to set yearly personal sales target.', type: 'textarea',
                    details: 'Setting the personal sales target dropdown when "all users" is selected'
                },
                'overview/set-self-sales-target': {
                    default: 'Set your yearly personal sales target', type: 'textarea',
                    details: 'Setting the personal sales target dropdown, for self / logged-in user'
                },
                'overview/set-other-sales-target': {
                    default: 'Set yearly personal sales target for', type: 'textarea',
                    details: 'Setting the personal sales target dropdown, for other user'
                },
                'overview/taskpipeline':          { default: 'Task pipeline' },
                'overview/taskpipeline-select-product': { default: 'Select a product to show task pipeline', type: 'textarea' },

                //
                // MAP VIEW PAGE (MOBILE)
                //
                'search/resource-added':            { default: 'Added', details: 'Lead/contact "added"' },
                'search/search-results':            { default: 'Search results' },

                //
                // MAP VIEW PAGE (MOBILE)
                //
                'map-view/pagetitle':               { default: 'All products', details: 'Map view page title (MOBILE)' },
                'map-view/pagetitle-product':       { default: '##1', details: '##1 = Selected product. Map view page title when one product is filtered (MOBILE)' },
                'map-view/search-placeholder':      { default: 'Search leads...', details: 'Map view search field placeholder text (MOBILE)' },

                //
                // MANAGE LEADS PAGE
                //
                'manage-leads/pagetitle':               { default: 'Manage leads', details: 'Page title' },
                'manage-leads/add-new-lead-tooltip': { default: 'Add new lead', details: 'Add new lead -button mouse hover tooltip text' },
                'manage-leads/leads':                   { default: 'Leads', details: 'Table top-left, "<count> Leads"' },
                'manage-leads/add-lead-open':           { default: 'Add new lead is open', details: 'Modal header when trying to leave page when Add New Lead -form is open' },
                'manage-leads/add-lead-open-text': {
                    default: 'Add new lead -dialog is open, are you sure you want to leave this page?', type: 'textarea',
                    details: 'Modal text when trying to leave page when Add New Lead -form is open'
                },

                // MANAGE LEADS / EXCEL EXPORT
                'manage-leads-excel-export/dialog-title':   { default: 'Excel export setup' }, 
                'manage-leads-excel-export/repeat-lead-per-contact': { default: 'Repeat lead per contact' }, 
                'manage-leads-excel-export/dialog-body':    { default: 'Repeating lead per contact will repeat each lead in the Excel for each contact linked to that lead.', type: 'textarea' }, 
                'manage-leads-excel-export/dialog-note':    { default: '*NOTE*: You can also export contacts by enabling "contacts" column in the table instead.', type: 'textarea' }, 
                'manage-leads-excel-export/no-leads':       { default: 'No leads to export', details: 'If there are no leads or filtering resulted in 0 leads' }, 

                // MANAGE LEADS / BULK ASSIGN/DELETE
                'manage-leads-bulk-manage/assignments':             { default: 'Assignments', details: 'Bulk assignments tab header' },
                'manage-leads-bulk-manage/statuses':                { default: 'Statuses', details: 'Bulk statuses tab header' },
                'manage-leads-bulk-manage/delete':                  { default: 'Delete', details: 'Bulk delete tab header' },
                'manage-leads-bulk-manage/assigned-products':       { default: 'Assigned products', details: 'BULK ASSIGN' },
                'manage-leads-bulk-manage/assignee-of-products':    { default: 'Assignee of products', details: 'BULK ASSIGN' },
                'manage-leads-bulk-manage/multiple-assignments':    { default: 'Multiple', details: 'BULK ASSIGN: when selected leads have multiple different assignees' },
                'manage-leads-bulk-manage/confirm-bulk-unassign-text':   {
                    default: 'Are you sure you want to remove "*##1*" product assignment *from all selected leads?*\n\nData related to the assignment will be permanently deleted from all selected leads.\n\n*This action cannot be undone!*',
                    type: 'textarea', details: 'BULK ASSIGN: Confirm dialog text when unselecting a product checkbox in bulk assignment'
                },
                'manage-leads-bulk-manage/successfully-assigned':   { default: 'Successfully assigned', details: 'BULK ASSIGN' },
                'manage-leads-bulk-manage/product':                 { default: 'Product', details: 'BULK STATUSES' },
                'manage-leads-bulk-manage/status':                  { default: 'Status', details: 'BULK STATUSES' },
                'manage-leads-bulk-manage/select-leads-statuses':   {
                    default: 'Select leads to set statuses',
                    type: 'textarea', details: 'BULK STATUSES: Text if no leads are selected'
                },
                'manage-leads-bulk-manage/set-statuses':            { default: 'Set statuses', details: 'BULK STATUSES: Button' },
                'manage-leads-bulk-manage/bulk-statuses-success':   { default: 'Statuses set', details: 'BULK STATUSES: Notification after successfully settings statuses' },
                'manage-leads-bulk-manage/select-leads-delete':   {
                    default: 'Select leads to bulk delete',
                    type: 'textarea', details: 'BULK DELETE: Text if no leads are selected'
                },
                'manage-leads-bulk-manage/bulk-delete-deleting': {
                    default: 'Bulk deleting *##1 leads*',
                    type: 'textarea', details: 'BULK DELETE: Text when leads are selected'
                },
                'manage-leads-bulk-manage/bulk-delete-button':      { default: 'Bulk delete leads' },
                'manage-leads-bulk-manage/successfully-deleted':    { default: 'Successfully deleted leads' },
                'manage-leads-bulk-manage/delete-leads-confirm-title': {
                    default: 'Are you sure you want to delete the selected leads?', type: 'textarea',
                    details: 'Modal confirmation title text when bulk deleting leads'
                },
                'manage-leads-bulk-manage/delete-leads-confirm-text': {
                    default: 'All related data will be lost (report/answers, assignments, sales) - This action cannot be undone!',
                    details: 'Modal confirmation body text when bulk deleting leads', type: 'textarea'
                },

                // MANAGE LEADS / BULK EDIT
                'manage-leads-bulk-edit/title':         { default: 'Bulk edit leads' },
                'manage-leads-bulk-edit/add-tags':      { default: 'Add tags' },
                'manage-leads-bulk-edit/remove-tags':   { default: 'Remove tags' },
                'manage-leads-bulk-edit/edited':        { default: 'Edited', details: 'When field has been edited/touched' },
                'manage-leads-bulk-edit/not-edited':    { default: 'Not edited', details: 'Field text when it has not been edited yet' },
                'manage-leads-bulk-edit/submit-button': { default: 'Bulk edit', details: 'Button text' },
                'manage-leads-bulk-edit/successfully-edited': { default: 'Successfully edited leads' },
                'manage-leads-bulk-edit/confirm-dialog1': {
                    default: 'Are you sure you want to change the fields marked as',
                    type: 'textarea', details: 'Confirm dialog text, PART 1'
                },
                'manage-leads-bulk-edit/confirm-dialog2': {
                    default: 'Edited', details: 'Confirm dialog text, PART 2'
                },
                'manage-leads-bulk-edit/confirm-dialog3': {
                    default: 'on ##1 leads?', details: 'Confirm dialog text, PART 3 (##1 = number of leads)'
                },

                // MANAGE LEADS / TABLE SETTINGS
                'manage-leads-table-settings/title':                { default: 'Table settings' },
                'manage-leads-table-settings/lead-details-tab':     { default: 'Lead details', details: 'Tab title' },
                'manage-leads-table-settings/lead-questions-tab':   { default: 'Lead questions', details: 'Tab title' },
                'manage-leads-table-settings/select-all':           { default: 'Select all', details: 'Lead details -tab' },
                'manage-leads-table-settings/clear-all':            { default: 'Clear all', details: 'Lead details -tab' },
                'manage-leads-table-settings/category-analytics':   { default: 'Analytics', details: 'Lead details -tab' },
                'manage-leads-table-settings/category-details':     { default: 'Product details', details: 'Lead details -tab' },
                'manage-leads-table-settings/category-contact':     { default: 'Company & contact details', details: 'Lead details -tab' },
                'manage-leads-table-settings/category-location':    { default: 'Location details', details: 'Lead details -tab' },
                'manage-leads-table-settings/category-other':       { default: 'Other', details: 'Lead details -tab' },
                'manage-leads-table-settings/select-products':      { default: 'Select products', details: 'Lead questions -tab' },
                'manage-leads-table-settings/clear-product-filter-to-reveal-all': { default: 'Clear product filter to reveal all products', details: 'Lead questions -tab', type: 'textarea' },
                'manage-leads-table-settings/no-products-with-questions-found': { default: 'No products with questions found', details: 'Lead questions -tab' },

                // MANAGE LEADS / TABLE (RTABLE)
                'manage-leads-table/retrieving-leads':                  { default: 'Retrieving leads', details: 'When app is first loading' },
                'manage-leads-table/num-leads':                         { default: 'Leads', details: 'Table top left, "8 leads"' },
                'manage-leads-table/excel-export-tooltip':              { default: 'Export table view to Excel', details: 'Excel export mouse hover tooltip' },
                'manage-leads-table/bulk-manage-tooltip':               { default: 'Bulk manage leads', details: 'Bulk assign/status/delete mouse hover tooltip' },
                'manage-leads-table/bulk-edit-tooltip':                 { default: 'Bulk edit lead details', details: 'Bulk edit mouse hover tooltip' },
                'manage-leads-table/table-settings-tooltip':            { default: 'Table settings', details: 'Table settings mouse hover tooltip' },
                'manage-leads-table/num-filters-active':                { default: 'Filter(s) active', details: '<num> filter(s) active' },
                'manage-leads-table/clear-filters':                     { default: 'Clear filters' },
                'manage-leads-table/leads-per-page':                    { default: 'Per page', details: 'Number of leads per page, pagination dropdown' },
                'manage-leads-table/sort-by':                           { default: 'Sort by', details: '"Sort by..."' },
                'manage-leads-table/sort-newest-first':                 { default: 'Sort newest first', details: 'Column sorting' },
                'manage-leads-table/sort-oldest-first':                 { default: 'Sort oldest first', details: 'Column sorting' },
                'manage-leads-table/sort-ascending':                    { default: 'Sort ascending', details: 'Column sorting' },
                'manage-leads-table/sort-descending':                   { default: 'Sort descending', details: 'Column sorting' },
                'manage-leads-table/column-label-sales_totals':         { default: 'Target vs Actual vs Last Year', details: 'Table column label' },
                'manage-leads-table/column-label-sales_totals_text':    { default: 'Target vs Actual vs Last Year (textual)', details: 'Table column label' },
                'manage-leads-table/column-label-sales_bymonth':        { default: 'Actual Per Month 2022', details: 'Table column label' },
                'manage-leads-table/column-label-sales_ytd':            { default: 'Actual Ytd in €', details: 'Table column label' },
                'manage-leads-table/column-label-sales_ytdvs':          { default: 'Actual Ytd vs Target', details: 'Table column label' },
                'manage-leads-table/column-label-phone_numbers':        { default: 'Phone number/s', details: 'Table column label' },
                'manage-leads-table/column-label-creation_timestamp':   { default: 'Created', details: 'Table column label' },

                // MANAGE LEADS / SIDEBAR
                'manage-leads-sidebar/lead-map': { default: 'Lead map', details: 'Collapse title' },
                'manage-leads-sidebar/kpi': { default: 'KPI', details: 'Collapse area title' },

                //
                // FILTERING
                //
                'filtering/filter-by':            { default: 'Filter by' },
                'filtering/filtering-criterias':  { default: 'Filtering criterias', details: 'ADVANCED FILTER TOOL' },
                'filtering/reset-all':            { default: 'Reset all', details: 'ADVANCED FILTER TOOL' },
                'filtering/details-tab':          { default: 'Details', details: 'ADVANCED FILTER TOOL: Tab title' },
                'filtering/answers-tab':          { default: 'Answers', details: 'ADVANCED FILTER TOOL: Tab title' },
                'filtering/time-tab':             { default: 'Time', details: 'ADVANCED FILTER TOOL: Tab title' },
                'filtering/field-sales_ytd':      { default: 'Sales YTD', details: 'Filter description' },
                'filtering/field-sales_ytdvs':    { default: 'Sales YTD vs Target', details: 'Filter description' },
                'filtering/field-phone_numbers':  { default: 'Phone numbers', details: 'Filter description' },
                'filtering/anyof-above':          { default: 'Any of above', details: 'ADVANCED FILTER TOOL: Filter option' },
                'filtering/anyof-tooltip': {
                    default: 'Filtering by ANY OF selected options. Click to change.', type: 'textarea',
                    details: '"any of" -filter longer description on column quickfilter button mouse hover tooltip'
                },
                'filtering/allof-above':          { default: 'All of above', details: 'ADVANCED FILTER TOOL: Filter option' },
                'filtering/allof-tooltip': {
                    default: 'Filtering by ALL OF selected options. Click to change.', type: 'textarea',
                    details: '"all of" -filter longer description on column quickfilter button mouse hover tooltip'
                },
                'filtering/add-q-to-filter-by':   { default: 'Add question to filter by', details: 'ADVANCED FILTER TOOL: Question filtering' },
                'filtering/search-by-product':    { default: 'Search by product', details: 'ADVANCED FILTER TOOL: Question filtering' },
                'filtering/no-q-filters-active':  { default: 'No question filters active', details: 'ADVANCED FILTER TOOL: Question filtering' },
                'filtering/anyof-selected':       { default: 'Any of selected', details: 'ADVANCED FILTER TOOL: Question filter option' },
                'filtering/exactly-as-selected':  { default: 'Exactly as selected', details: 'ADVANCED FILTER TOOL: Question filter option' },
                'filtering/reported-time':        { default: 'Reported time', details: 'ADVANCED FILTER TOOL: Time filtering' },


                //
                // ADD / EDIT LEAD
                //
                'add-edit-lead/add-new-lead':                       { default: 'Add new lead' },
                'add-edit-lead/add-lead-contacts':                  { default: 'Add lead/contacts', details: 'MOBILE add lead/contacts page title' },
                'add-edit-lead/link-contacts-to-existing':          { default: 'Link contact(s) to existing lead', details: 'Only visible on MOBILE add new lead/contact page' },
                'add-edit-lead/or-add-new-lead':                    { default: '*...or add new lead*:', details: 'Only visible on MOBILE add new lead/contact page' },
                'add-edit-lead/contacts-saved-existing':            { default: 'Contact(s) saved to existing lead', details: 'MOBILE: Notification after adding/editing contacts on EXISTING lead' },
                'add-edit-lead/new-contacts-saved':                 { default: 'Contacts saved to Rudolf', details: 'MOBILE: Notification after adding new contacts but not linking them to any lead yet ("Save & exit" pressed)' },
                'add-edit-lead/save-n-proceed':                     { default: 'Save & proceed to questions', details: 'Mobile add lead/contacts page'},
                'add-edit-lead/main-details-column-header':         { default: 'Main details', details: 'Lead details column header' },
                'add-edit-lead/additional-details-column-header':   { default: 'Additional details', details: 'Lead details column header' },
                'add-edit-lead/field-created':                      { default: 'Created' },
                'add-edit-lead/field-phonenumbers':                 { default: 'Phone number/s' },
                'add-edit-lead/field-comment':                      { default: 'Comment' },
                'add-edit-lead/hq-parent':                          { default: 'HQ/parent' },
                'add-edit-lead/required-field':                     { default: 'Required field' },
                'add-edit-lead/document-links-title':               { default: 'Document links' },
                'add-edit-lead/edit-doc-link-tooltip':              { default: 'Edit this document link' },
                'add-edit-lead/remove-doc-link-tooltip':            { default: 'Remove this document link' },
                'add-edit-lead/link-desc-placeholder':              { default: 'Link description' },
                'add-edit-lead/url-placeholder':                    { default: 'URL to file/document' },
                'add-edit-lead/invalid-url':                        { default: 'Invalid URL' },
                'add-edit-lead/contacts-header':                    { default: 'Contacts' },
                'add-edit-lead/link-contact':                       { default: 'Link contact' },
                'add-edit-lead/primary-contact':                    { default: 'Primary contact' },
                'add-edit-lead/contacts-server-error1':             { default: 'Server failure while attempting to save this contact', type: 'textarea' },
                'add-edit-lead/contacts-server-error2':             { default: 'Try again or contact Rudolf support if this problem persists.', type: 'textarea' },
                'add-edit-lead/make-primary-contact':               { default: 'Make this the primary contact for this lead', type: 'textarea' },
                'add-edit-lead/primary-contact':                    { default: 'Primary contact' },
                'add-edit-lead/create-new-lead-button':             { default: 'Create new lead', details: 'Button text' },
                'add-edit-lead/delete-contact-confirm-text1': {
                    default: 'Are you sure you want to *permanently delete* contact "##1" from *all leads and Rudolf?*',
                    type: 'textarea', details: '##1 = Contact name to be deleted'
                },
                'add-edit-lead/delete-contact-confirm-text2':       { default: 'This action cannot be undone!' },
                'add-edit-lead/contact-deleted':                    { default: 'Contact deleted' },
                'add-edit-lead/delete-doc-link-confirm-text': {
                    default: 'Are you sure you want to *delete* this document link?', type: 'textarea'
                },
                'add-edit-lead/document-link-deleted':              { default: 'Document link deleted' },
                'add-edit-lead/change-hq-confirm-text': {
                    default: 'Lead "*##1*" has been assigned as the headquarters/parent company for *##2*. Would you like to reassign this lead to be the HQ/parent company instead?', type: 'textarea',
                    details: '##1 = Current HQ lead name, ##2 = Project name'
                },
                'add-edit-lead/duplicate-leads-by-name':            { default: 'Duplicate leads by name' },
                'add-edit-lead/duplicate-leads-by-location':        { default: 'Duplicate leads by location' },
                'add-edit-lead/check-required-fields':              { default: 'Check required fields' },
                'add-edit-lead/create-anyway':                      { default: 'Create anyway', details: 'Confirm modal button text' },
                'add-edit-lead/save-anyway':                        { default: 'Save anyway', details: 'Confirm modal button text' },
                'add-edit-lead/lead-updated':                       { default: 'Lead updated' },


                //
                // LEAD PAGE
                //
                'lead-page/pagetitle':                      { default: 'Leads', details: 'Lead page title, LEADS-<lead name>'  },
                'lead-page/no-access-or-deleted': {
                    default: 'You don\'t have access to this lead or lead has been deleted.', type: 'textarea'
                },
                'lead-page/go-back-manage-leads':           { default: 'Go back to Manage Leads' },
                'lead-page/company-and-contact-details':    { default: 'Company and contact details' },
                'lead-page/close-company-and-contact-details': { default: 'Close company and contact details' },
                'lead-page/edit-lead-button-tooltip':       { default: 'Edit lead details', details: 'Button mouse hover tooltip text' },
                'lead-page/copy-lead-button-tooltip':       { default: 'Copy lead', details: 'Button mouse hover tooltip text' },
                'lead-page/delete-lead-button-tooltip':     { default: 'Delete lead', details: 'Button mouse hover tooltip text' },
                'lead-page/products-header':                { default: 'Products', details: 'Assigned products header text' },
                'lead-page/assign-product':                 { default: 'Assign product' },
                'lead-page/product-assigned':               { default: 'Product assigned' },
                'lead-page/no-assignments-text': {
                    default: 'This lead has no product/user assignments. Make an assignment to start generating a report.',
                    type: 'textarea'
                },
                'lead-page/remove-product-assignment':      { default: 'Remove product assignment' },
                'lead-page/assign-right-only':              { default: 'Assign right only' },
                'lead-page/report-view':                    { default: 'Report view' },
                'lead-page/go-to-report-view':              { default: 'Go to Report view' },
                'lead-page/kpi-view':                       { default: 'KPI view' },
                'lead-page/go-to-kpi-view':                 { default: 'Go to KPI view' },
                'lead-page/select-product':                 { default: 'Select a product to view report', type: 'textarea' },
                'lead-page/click-to-select':                { default: 'Click to select' },
                'lead-page/limited-view-report-not-accessible': {
                    default: 'Report not accessible; you have limited view access to this product.',
                    type: 'textarea'
                },
                'lead-page/assign-right-report-not-accessible': {
                    default: 'Report not accessible; you only have assign rights to this product.',
                    type: 'textarea'
                },
                'lead-page/report-user-assignment-needed': {
                    default: 'Make a user assignment to start generating a report for this product.',
                    type: 'textarea'
                },
                'lead-page/no-questions': {
                    default: 'This product has no questions - add some to start generating a report.',
                    type: 'textarea'
                },
                'lead-page/required-question':              { default: 'Required question' },
                'lead-page/answer-must-be-a-number':        { default: 'Answer must be a number' },
                'lead-page/fill-all-required-questions':    { default: 'Fill all required questions' },
                'lead-page/save-report':                    { default: 'Save report', details: 'Button text' },
                'lead-page/report-saved':                   { default: 'Report saved' },
                'lead-page/report-view-access-only': {
                    default: 'You only have view access to this report.', type: 'textarea'
                },
                'lead-page/delete-lead-confirm-text':{
                    default: "Are you sure you want to delete this lead? (*##1*)\n\nAll related data will be lost (report/answers, assignments, sales).\n\n*This action cannot be undone!*",
                    type: 'textarea', details: '##1 = Lead name to be deleted'
                },
                'lead-page/lead-deleted':                   { default: 'Lead deleted' },
                'lead-page/copy-lead-title':                { default: 'Copy lead' },
                'lead-page/copy-lead-new-lead-name-text': {
                    default: 'Name for new lead that will be a copy of this lead', type: 'textarea'
                },
                'lead-page/copy-lead-new-lead-name':        { default: 'New lead name' },
                'lead-page/copy-of':                        { default: 'Copy of ##1', details: '##1 = Lead being copied' },
                'lead-page/copy-lead-and-go':               { default: 'Copy and go to new lead' },
                'lead-page/lead-copied':                    { default: 'Lead copied' },
                'lead-page/confirm-remove-product-assignment': {
                    default: "Are you sure you want to remove this product assignment?\n\nData related to the assignment will be permanently deleted: *report answers, KPI sales data*.\n\nThis action cannot be undone!",
                    type: 'textarea'
                },
                'lead-page/confirm-remove-product-assignment-button': { default: 'Remove assignment' },
                'lead-page/product-assignment-removed':     { default: 'Product assignment removed' },
                'lead-page/user-assignment-changed':        { default: 'Assignment changed' },
                'lead-page/unsaved-changes-title':          { default: 'Unsaved changes', details: 'You have unsaved changes -dialog' },
                'lead-page/unsaved-reporting':              { default: 'Reporting section', details: 'You have unsaved changes -dialog' },
                'lead-page/unsaved-lead-details':           { default: 'Lead details', details: 'You have unsaved changes -dialog' },
                'lead-page/unsaved-continue-and-discard':   { default: 'Continue and discard unsaved changes?', details: 'You have unsaved changes -dialog' },

                // LEAD PAGE COMMENTS
                'lead-page-comments/report-history':                 { default: 'Report history' },
                'lead-page-comments/add-new-comment':                { default: 'Add new comment' },
                'lead-page-comments/no-report-history':              { default: 'No report history yet' },
                'lead-page-comments/no-comments':                    { default: 'No comments yet' },
                'lead-page-comments/system-comment-assignment':{
                    default: "(*##1*) Assigned to ##2 by ##3",
                    type: 'textarea', details: 'REPORT HISTORY / Automatic system comment. ##1 = Product name that was assigned, ##2 = User it was assigned to, ##3 = User who made the assignment'
                },
                'lead-page-comments/system-comment-unassignment':{
                    default: "(*##1*) ##2 unassigned by ##3",
                    type: 'textarea', details: 'REPORT HISTORY / Automatic system comment. ##1 = Product name that was unassigned from, ##2 = User who was unassigned, ##3 = User who made the unassignment'
                },
                'lead-page-comments/system-comment-reported':{
                    default: "(*##1*) Reported by ##2",
                    type: 'textarea', details: 'REPORT HISTORY / Automatic system comment. ##1 = Product name that was reported on, ##2 = User who did the reporting'
                },
                'lead-page-comments/system-comment-reported-status-changed':{
                    default: "(*##1*) Reported by ##2 and status reported as ##3",
                    type: 'textarea', details: 'REPORT HISTORY / Automatic system comment. ##1 = Product name that was reported on, ##2 = User who did the reporting, ##3 = New status that was reported'
                },


                // LEAD PAGE / KPI VIEW
                'lead-page-kpi/breakdown-by-product':           { default: 'Breakdown by product' },
                'lead-page-kpi/breakdown-products-header':      { default: 'Products' },
                'lead-page-kpi/breakdown-ytd-header':           { default: 'Actual YTD ##1', details: '##1 = month' },
                'lead-page-kpi/breakdown-ytd-last-year-header': { default: 'YTD ##1 Last Year', details: '##1 = month' },
                'lead-page-kpi/breakdown-difference-header':    { default: 'Difference' },
                'lead-page-kpi/edit-target-and-numbers':        { default: 'Edit target & numbers' },
                'lead-page-kpi/enter-yearly-sales-target':      { default: 'Enter yearly sales target' },
                'lead-page-kpi/save-target-and-numbers':        { default: 'Save target & numbers' },
                'lead-page-kpi/actual-in-month':                { default: 'Actual in ##1', details: '##1 = month' },
                'lead-page-kpi/reach':                          { default: 'Reach: ##1%', details: '##1 = percentage of reached sales that month' },
                'lead-page-kpi/saved':                          { default: 'KPI target and numbers saved' },


                //
                // ACTIVITIES PAGE
                //
                'activities/pagetitle':                 { default: 'My activities', details: 'Page title' },
                'activities/upcoming-tasks':            { default: 'Upcoming tasks' },
                'activities/meetings':                  { default: 'Meetings' },
                'activities/no-meetings':               { default: 'You have no upcoming meetings right now.', type: 'textarea' },
                'activities/contacts-title':            { default: 'Contacts' },
                'activities/to-call':                   { default: 'To call' },
                'activities/to-call-list':              { default: 'To call list' },
                'activities/add-to-call-list-manual':   { default: 'Add to call list (manual)' },
                'activities/edit-call-list-item':       { default: 'Edit call list item' },
                'activities/call-list-item-saved':      { default: 'Call list item saved' },
                'activities/call-list-item-deleted':    { default: 'Call list item deleted' },
                'activities/per-page':                  { default: 'Per page' },
                'activities/contact-list':              { default: 'Contact list' },
                'activities/add-new-contact':           { default: 'Add new contact' },
                'activities/edit-contact':              { default: 'Edit contact' },
                'activities/add-to-call-list':          { default: 'Add to Call List' },
                'activities/required-field':            { default: 'Required field' },
                'activities/call-list-item-phone-numbers': { default: 'Phone number/s' },
                'activities/call-list-item-company':    { default: 'Company' },
                'activities/already-in-call-list':      { default: 'Already in Call List' },
                'activities/confirm-delete-call-list-item': {
                    default: 'Are you sure you want to delete call list item "*##1*"?\n\n*This call list item is not saved in Rudolf and will be lost permanently.*',
                    type: 'textarea'
                },
                'activities/contact-added-to-call-list': { default: 'Contact added to Call List' },
                'activities/to-do':                     { default: 'To do' },
                'activities/to-do-list':                { default: 'To Do list' },
                'activities/add-todo-item':             { default: 'Add new To Do item' },
                'activities/edit-todo-item':            { default: 'Edit To Do item' },
                'activities/input-todo-text':           { default: 'Input text for To Do item' },
                'activities/select-todo-date':          { default: 'Select due date for To Do item' },
                'activities/todo-saved':                { default: 'To Do saved' },
                'activities/todo-deleted':              { default: 'To Do deleted' },
                'activities/todos-show-dropdown':       { default: 'Show', details: 'Show all / upcoming TODOs' },
                'activities/todos-show-all':            { default: 'All' },
                'activities/todos-show-upcoming':       { default: 'Upcoming' },
                'activities/todos-show-completed':      { default: 'Completed' },
                'activities/todos-show-due-dates':      { default: 'Due dates' },
                'activities/todos-due-date-on':         {
                    default: 'Due date on ##1',
                    details: 'Due date TODO item in todo list, ##1 = lead and product name for due date'
                },
                'activities/related-lead':              { default: 'Related lead', details: 'Todo item related lead' },
                'activities/confirm-delete-contact-text1': {
                    default: 'Are you sure you want to delete contact "*##1*"?',
                    type: 'textarea', details: '##1 = Contact person full name'
                },
                'activities/confirm-delete-contact-text2': {
                    default: 'Contact will be removed from *##1* leads',
                    type: 'textarea', details: '##1 = No. of leads this contact will get removed from'
                },
                'activities/confirm-delete-contact-text3': {
                    default: '*This action cannot be undone!*',
                    type: 'textarea'
                },
                'activities/contact-saved':             { default: 'Contact saved' },
                'activities/contact-deleted':           { default: 'Contact deleted' },



                //
                // CONTACTS
                //
                'contacts/already-exists':        { default: 'Contact already exists' },
                'contacts/phone1':                { default: 'Phone #1' },
                'contacts/phone2':                { default: 'Phone #2' },
                'contacts/phone-numbers':         { default: 'Phone number/s' },
                'contacts/title':                 { default: 'Position/Title' },
                'contacts/invalid-linkedin-url':  { default: 'Invalid LinkedIn URL' },
                'contacts/related-leads':         { default: 'Related leads' },



                //
                // NOTIFICATIONS
                //
                'notifications/notifications':          { default: 'Notifications' },
                'notifications/mark-all-as-read':       { default: 'Mark all as read' },
                'notifications/remove-all-read':        { default: 'Remove all read notifications' },
                'notifications/remove-notification':    { default: 'Remove notification' },
                'notifications/mark-as-read':           { default: 'Mark as read' },
                'notifications/no-new-notifications':   { default: 'No new notifications, you\'re all caught up!' },
                'notifications/assignment': {
                    default: 'You have been assigned to ##1 / ##2 by ##3',
                    type: 'textarea', details: 'Assignment notification, ##1 = lead name, ##2 = product name, ##3 = user who made assignment'
                },
                'notifications/status_change': {
                    default: 'Status reported as ##1 on ##2 / ##3 by ##4',
                    type: 'textarea', details: 'Status change notification, ##1 = New status, ##2 = lead name, ##3 = product name, ##4 = user who changed status'
                },
                'notifications/next_appt_change': {
                    default: 'New next appointment date reported on ##1 / ##2 by ##3',
                    type: 'textarea', details: 'Next appointment change notification, ##1 = lead name, ##2 = product name, ##3 = user who reported next appointment'
                },
                'notifications/next_appt_change_removed': {
                    default: 'Next appointment date *removed* on ##1 / ##2 by ##3',
                    type: 'textarea', details: 'Next appointment change notification, ##1 = lead name, ##2 = product name, ##3 = user who reported next appointment'
                },
                'notifications/due_date_change': {
                    default: 'New due date reported on ##1 / ##2 by ##3',
                    type: 'textarea', details: 'Due date change notification, ##1 = lead name, ##2 = product name, ##3 = user who reported due date'
                },
                'notifications/due_date_change_removed': {
                    default: 'Due date *removed* on ##1 / ##2 by ##3',
                    type: 'textarea', details: 'Due date removal notification, ##1 = lead name, ##2 = product name, ##3 = user who removed due date'
                },
                'notifications/due_date_reminder_1week': {
                    default: '*REMINDER*: Due date on ##1 / ##2 in *1 week*!',
                    type: 'textarea', details: 'Due date 1 week reminder message, ##1 = lead name, ##2 = product name'
                },
                'notifications/new_lead_comment': {
                    default: 'New comment on ##1 by ##2',
                    type: 'textarea', details: 'New lead comment notification, ##1 = lead name, ##2 = user who made the comment'
                },



                //
                // KPI
                //
                'kpi/salestarget':                { default: 'Sales target' },
                'kpi/sales-reached-in':           { default: 'Sales reached in', details: 'Sales reached in <year>' },
                'kpi/target':                     { default: 'Target' },
                'kpi/actual':                     { default: 'Actual' },
                'kpi/last-year':                  { default: 'Last year' },
                'kpi/ytd':                        { default: 'YTD', details: '"Year to date", abbreviated' },
                'kpi/yearly-target-view':         { default: 'Yearly target view', details: 'KPI chart subtitle' },
                'kpi/monthly-target-view':        { default: 'Monthly target view', details: 'KPI chart subtitle' },
                'kpi/actual-ytd':                 { default: 'Actual YTD in €', details: 'Manage leads sidebar, KPI section' },
                'kpi/actual-ytd-vs-target':       { default: 'Actual YTD vs Target', details: 'Manage leads sidebar, KPI section' },


                //
                // SETUP PRODUCTS
                //
                'setup-products/pagetitle':             { default: 'Set up: Products', details: 'Page title' },
                'setup-products/product-list':          { default: 'Product list', details: 'Section title' },
                'setup-products/sorting-note':          { default: 'Note: Sorting by drag & drop is disabled while product filter is active.', type: 'textarea' },
                'setup-products/no-products':           { default: 'No products found' },
                'setup-products/setup-product-title':   { default: 'Set up ##1', details: '##1 = Product name' },
                'setup-products/confirm-delete-product-text': {
                    default: 'Are you sure you want to delete product "*##1*"?\n\nAll related data will be lost (reports, questions, answers, sales, assignments, statuses, forms).\n\n*This action cannot be undone!*',
                    type: 'textarea', details: '##1 = Product name to be deleted'
                },
                'setup-products/new-product-created':   { default: 'New product created' },
                'setup-products/product-deleted':       { default: 'Product deleted' },


                //
                // SETUP STATUSES
                //
                'setup-statuses/statuses-title':        { default: 'Statuses', details: 'Section title' },
                'setup-statuses/status':                { default: 'Status' },
                'setup-statuses/add-new-status':        { default: 'Add new status' },
                'setup-statuses/save-statuses':         { default: 'Save statuses' },
                'setup-statuses/status-name-required':  { default: 'Status name is required' },
                'setup-statuses/status-added':          { default: 'Status added' },
                'setup-statuses/status-deleted':        { default: 'Status deleted' },
                'setup-statuses/statuses-saved':        { default: 'Statuses saved' },
                'setup-statuses/confirm-delete-status-text': {
                    default: 'Are you sure you want to delete status "*##1*"?',
                    type: 'textarea', details: '##1 = Status name to be deleted'
                },

                //
                // SETUP QUESTIONS
                //
                'setup-questions/questions-title':                  { default: 'Questions', details: 'Section title' },
                'setup-questions/add-new-question':                 { default: 'Add new question' },
                'setup-questions/edit-question':                    { default: 'Edit question' },
                'setup-questions/required-question':                { default: 'Required question' },
                'setup-questions/question-description':             { default: 'Description' },
                'setup-questions/two-or-more-options-required':     { default: 'Two or more options are required' },
                'setup-questions/duplicate-options-not-allowed':    { default: 'Duplicate options not allowed' },
                'setup-questions/question-option':                  { default: 'Option', details: 'As in: option for a multiple choice question' },
                'setup-questions/add-option':                       { default: 'Add option', details: 'As in: option for a multiple choice question' },
                'setup-questions/slider-min-max-error':             { default: 'Make sure minimum value is less than maximum, and vice-versa', type: 'textarea' },
                'setup-questions/slider-min-range':                 { default: 'Slider min range' },
                'setup-questions/slider-max-range':                 { default: 'Slider max range' },
                'setup-questions/change-form-name':                 { default: 'Change form name' },
                'setup-questions/form-name':                        { default: 'Form name' },
                'setup-questions/question-added':                   { default: 'Question added' },
                'setup-questions/question-saved':                   { default: 'Question saved' },
                'setup-questions/question-exists':                  { default: 'Question already exists' },
                'setup-questions/personal-sales-question-exists': {
                    default: 'Personal sales question already exists for this product', type: 'textarea'
                },
                'setup-questions/form-saved':                       { default: 'Form saved' },
                'setup-questions/confirm-delete-question-text':     {
                    default: 'Are you sure you want to delete question "*##1*"?',
                    type: 'textarea', details: '##1 = Question to be deleted'
                },
                'setup-questions/question-deleted':                 { default: 'Question deleted' },
                'setup-questions/questiontype-multiple':            { default: 'Multiple', details: 'Multiple choice question, short description (user can select 1 answer)' },
                'setup-questions/questiontype-multiple-long':       { default: 'Multiple choice limited (user can only select 1 answer)', type: 'textarea', details: 'Multiple choice question, long description (setup page)' },
                'setup-questions/questiontype-multiplemany':        { default: 'Multiple many', details: 'Multiple choice question, short description (user can select several answers)' },
                'setup-questions/questiontype-multiplemany-long':   { default: 'Multiple choice unlimited (user can select several answers)', type: 'textarea', details: 'Multiple choice question, long description (setup page)' },
                'setup-questions/questiontype-slider':              { default: 'Slider', details: 'Slider question, short description' },
                'setup-questions/questiontype-slider-long':         { default: 'Slider (select a value by using a sliding bar)', type: 'textarea', details: 'Slider question, long description (setup page)' },
                'setup-questions/questiontype-value':               { default: 'Value (free text answer)', details: 'Free text question description' },
                'setup-questions/questiontype-number':              { default: 'Value (number)', details: 'Number question description' },
                'setup-questions/questiontype-personalsales':       { default: 'Personal sales question', details: 'Personal sales question, short description' },
                'setup-questions/questiontype-personalsales-long':  { default: 'Personal sales question (1 per product)', type: 'textarea', details: 'Personal sales question, long description (setup page)' },


                //
                // SETUP / RESOURCE ORDERING
                //
                'setup-ordering/change-order':                      { default: 'Change order' },
                'setup-ordering/change-type-order': {
                    default: 'Change ##1 order',
                    details: '##1 = type of resource being sorted (questions, statuses, ...)'
                },


                //
                // SETUP KPI
                //
                'setup-kpi/section-title':                  { default: 'KPI setup', details: 'Section title' },
                'setup-kpi/upload-kpi-data':                { default: 'Upload KPI data' },
                'setup-kpi/upload-for':                     { default: 'Upload for', details: 'As in: Upload for <month>' },
                'setup-kpi/select-month-error': {
                    default: 'Select month to upload KPI data for',
                    type: 'textarea'
                },
                'setup-kpi/attach-file':                    { default: 'Attach file' },
                'setup-kpi/select-file-error':              { default: 'Select file', details: 'Error reminder about needing to select file' },
                'setup-kpi/monthly-emphasis':               { default: 'Monthly emphasis' },
                'setup-kpi/upload-success':                 { default: 'KPI data uploaded successfully' },
                'setup-kpi/kpi-weights-saved':              { default: 'KPI weights set' },



                //
                // SETUP USERS
                //
                'setup-users/pagetitle':                { default: 'Set up: Subscription & User Role', details: 'Page title' },
                'setup-users/add-new-user':             { default: 'Add new user' },
                'setup-users/edit-user':                { default: 'Edit user' },
                'setup-users/role-admin':               { default: 'Admin', details: 'Admin user role' },
                'setup-users/role-user':                { default: 'User', details: 'Regular user role' },
                'setup-users/role-basic':               { default: 'Basic', details: 'Basic user role' },
                'setup-users/user-phone-exists':        { default: 'User with this phone number already exists' },
                'setup-users/user-email-exists':        { default: 'User with this email already exists' },
                'setup-users/role-options':             { default: 'Role options' },
                'setup-users/role-options-disabled': {
                    default: 'Role is set as "##1" - role options disabled.', type: 'textarea',
                    details: '##1 = role (superadmin / admin)'
                },
                'setup-users/role-options-disabled-note': {
                    default: 'Admin users automatically have full visibility to the entire system.',
                    type: 'textarea',
                },
                'setup-users/user-visibility-title':    { default: 'Include visibility of other users' },
                'setup-users/search-team-members':      { default: 'Search team members' },
                'setup-users/add-all':                  { default: 'Add all' },
                'setup-users/access-level-limited-view': { default: 'Limited view', details: 'Visibility access level' },
                'setup-users/access-level-view':        { default: 'View', details: 'Visibility access level' },
                'setup-users/access-level-edit':        { default: 'Edit', details: 'Visibility access level' },
                'setup-users/product-visibilities-title': { default: 'Product visibilities' },
                'setup-users/search-products':          { default: 'Search products' },
                'setup-users/assign-rights-title':      { default: 'Assign rights' },
                'setup-users/confirm-delete-user-text': {
                    default: 'Are you sure you want to delete user "*##1*"?\n\nLeads assigned to this user will be set to unassigned.',
                    type: 'textarea'
                },
                'setup-users/confirm-reset-pwd-text': {
                    default: 'Are you sure you want to reset password for user *##1*?',
                    type: 'textarea'
                },
                'setup-users/user-pwd-reset-new-password': {
                    default: 'New password for account *##1*\n\n##2',
                    type: 'textarea', details: '##1 = account email, ##2 = new password'
                },
                'setup-users/user-created': {
                    default: 'User created, email instructions for setting password sent to ##1',
                    type: 'textarea', details: '##1 = new user email address'
                },
                'setup-users/user-saved':               { default: 'User information saved' },
                'setup-users/user-deleted':             { default: 'User ##1 deleted', details: '##1 = user email that was deleted' },
                'setup-users/user-delete-not-found':    { default: 'User not found (already deleted?)' },


                //
                // SETUP DATA
                //
                'setup-data/pagetitle':            { default: 'Set up: Data', details: 'Page title' },
                'setup-data/clean-database':       { default: 'Clean database' },
                'setup-data/export-contacts':      { default: 'Export contacts to Excel' },
                'setup-data/import-leads':         { default: 'Import leads Excel' },
                'setup-data/upload-assignments':   { default: 'Upload assignments to Rudolf' },
                'setup-data/assigned-products':    { default: 'Assigned products' },
                'setup-data/assignee-of-products': { default: 'Assignee of products' },
                'setup-data/create-duplicates':    { default: 'Create new leads out of duplicates instead of updating existing' },
                'setup-data/upload-leads':         { default: 'Upload leads' },
                'setup-data/clean-db-confirm-text': {
                    default: '*All data from the system will be erased*\n\n*Type "erase all data" in the box below to confirm.*',
                    type: 'textarea'
                },
                'setup-data/erase':                { default: 'Erase' },
                'setup-data/parsing-excel-file':   { default: 'Parsing excel file' },
                'setup-data/importing-leads':      { default: 'Importing leads' },
                'setup-data/geocoding-leads':      { default: 'Geocoding leads' },
                'setup-data/setting-assignments':  { default: 'Setting lead assignments' },
                'setup-data/db-erased':            { default: 'Database erased' },
                'setup-data/no-contacts-to-export': { default: 'No contacts to export' },


                //
                // SETUP LABELS
                //
                'setup-labels/pagetitle':           { default: 'Set up: Labels', details: 'Page title' },
                'setup-labels/leadname':            { default: 'Lead name', details: 'Label field' },
                'setup-labels/product':             { default: 'Product(s)', details: 'Label field' },
                'setup-labels/project':             { default: 'Project/belongs to', details: 'Label field' },
                'setup-labels/assignment':          { default: 'Assignments', details: 'Label field' },
                'setup-labels/status':              { default: 'Statuses', details: 'Label field' },
                'setup-labels/full_address':        { default: 'Full address', details: 'Label field' },
                'setup-labels/address':             { default: 'Address', details: 'Label field' },
                'setup-labels/addressmisc':         { default: 'Additional address', details: 'Label field' },
                'setup-labels/city':                { default: 'City', details: 'Label field' },
                'setup-labels/state':               { default: 'State', details: 'Label field' },
                'setup-labels/country':             { default: 'Country', details: 'Label field' },
                'setup-labels/region':              { default: 'Region', details: 'Label field' },
                'setup-labels/tags':                { default: 'Tags', details: 'Label field' },
                'setup-labels/website':             { default: 'Website', details: 'Label field' },
                'setup-labels/email':               { default: 'Email', details: 'Label field' },
                'setup-labels/contactnumber':       { default: 'Contact number', details: 'Label field' },
                'setup-labels/mobilenumber':        { default: 'Mobile number', details: 'Label field' },
                'setup-labels/customertype':        { default: 'Customer type', details: 'Label field' },
                'setup-labels/projecttype':         { default: 'Project type', details: 'Label field' },
                'setup-labels/nextappointment':     { default: 'Next appointment', details: 'Label field' },
                'setup-labels/deadline':            { default: 'Due date', details: 'Label field' },
                'setup-labels/zone1':               { default: 'Work zone 1', details: 'Label field' },
                'setup-labels/zone2':               { default: 'Work zone 2', details: 'Label field' },
                'setup-labels/zone3':               { default: 'Work zone 3', details: 'Label field' },
                'setup-labels/zone4':               { default: 'Work zone 4', details: 'Label field' },
                'setup-labels/longitude':           { default: 'Longitude', details: 'Label field' },
                'setup-labels/latitude':            { default: 'Latitude', details: 'Label field' },
                'setup-labels/comments':            { default: 'Comments', details: 'Label field' },
                'setup-labels/contact':             { default: 'Contact', details: 'Label field' },
                'setup-labels/identifier':          { default: 'Identifier', details: 'Label field' },
                'setup-labels/last_comment':        { default: 'Last comment', details: 'Label field' },
                'setup-labels/contacts':            { default: 'Contacts', details: 'Label field' },
                'setup-labels/save-labels':         { default: 'Save labels', details: 'Label field' },
                'setup-labels/labels-saved':        { default: 'Labels saved', details: 'Label field' },


                //
                // SETUP LOCALIZATION
                //
                'setup-localization/pagetitle':   { default: 'Set up: Localization', details: 'Page title' },


                //
                // ACCOUNT PAGE
                //
                'account/pagetitle':              { default: 'My account', details: 'Page title' },
                'account/logged-in-email':        { default: 'Logged in email' },
                'account/logout-button-text':     { default: 'Log-out', details: 'Button text' },
                'account/edit-my-information':    { default: 'Edit my information' },
                'account/refer-rudolf':           { default: 'Refer Rudolf' },
                'account/refer-addt-comments':    { default: 'Additional comments', details: 'Refer Rudolf -section' },
                'account/change-password':        { default: 'Change password' },
                'account/old-password':           { default: 'Old password' },
                'account/new-password':           { default: 'New password' },
                'account/confirm-password':       { default: 'Confirm new password' },
                'account/password-requirements': {
                    default: 'Password must contain atleast 7 letters, 2 numbers and a special character.',
                    type: 'textarea', details: 'Password requirements error notification'
                },
                'account/password-mismatch':      { default: 'Passwords do not match' },
                'account/reset-pwd-default':      { default: 'Or reset password to default' },
                'account/default-pwd-is':         { default: 'Default password is' },
                'account/lead-page-settings':     { default: 'Lead page settings' },
                'account/lead-page-settings-note': {
                    default: 'Select fields visible by default on Lead Details -page',
                    type: 'textarea'
                },
                'account/search-fields':          { default: 'Search fields' },


                //
                // QR CODE SCANNER
                //
                'qr-code-scanner/top-text':   {
                    default: 'Scan a QR code to import lead and contact into Rudolf',
                    type: 'textarea'
                },
                'qr-code-scanner/not-allowed-error':   {
                    default: 'You need to grant camera access permission',
                    type: 'textarea'
                },
                'qr-code-scanner/not-found-error':   {
                    default: 'No camera on this device',
                    type: 'textarea'
                },
                'qr-code-scanner/insecure-context':   {
                    default: 'Camera access is only permitted in secure context (HTTPS)',
                    type: 'textarea'
                },
                'qr-code-scanner/not-readable-error':   {
                    default: 'Camera not readable; is it already in use?',
                    type: 'textarea'
                },
                'qr-code-scanner/cameras-not-suitable':   {
                    default: 'Installed cameras are not suitable',
                    type: 'textarea'
                },
                'qr-code-scanner/stream-api-not-supported':   {
                    default: 'Stream API is not supported in this browser',
                    type: 'textarea'
                },
                'qr-code-scanner/invalid-qr-code':   {
                    default: 'Invalid QR code',
                    type: 'textarea'
                },
                'qr-code-scanner/invalid-vcard-format':   {
                    default: 'Invalid vCard format in QR code',
                    type: 'textarea'
                },
                'qr-code-scanner/error-while-parsing-qr-code':   {
                    default: 'Error while parsing QR code: ##1',
                    type: 'textarea', details: '##1 = Error details, if any'
                },
                'qr-code-scanner/unable-to-create-contact-no-firstname':   {
                    default: 'Unable to create contact; no First Name in QR code',
                    type: 'textarea'
                },
                'qr-code-scanner/unable-to-create-contact-no-lastname':   {
                    default: 'Unable to create contact; no Last Name in QR code',
                    type: 'textarea'
                },
                'qr-code-scanner/unable-to-create-contact-no-phone':   {
                    default: 'Unable to create contact; no Phone Number in QR code',
                    type: 'textarea'
                },
                'qr-code-scanner/created-new-contact':   {
                    default: 'Created new contact "##1"',
                    type: 'textarea', details: '##1 = Contact name'
                },
                'qr-code-scanner/found-existing-contact':   {
                    default: 'Found existing contact "##1"',
                    type: 'textarea', details: '##1 = Contact name'
                },
                'qr-code-scanner/error-while-creating-contact':   {
                    default: 'Error while creating contact: ##1',
                    type: 'textarea', details: '##1 = Error details, if any'
                },
                'qr-code-scanner/existing-lead-found':   {
                    default: 'Existing lead found from QR code',
                    type: 'textarea'
                },
                'qr-code-scanner/existing-lead-updated-with-contact':   {
                    default: 'Existing lead ##1 updated with contact "##2"',
                    type: 'textarea', details: '##1 = Existing lead name, ##2 = Contact name'
                },
                'qr-code-scanner/error-while-linking-contact-to-lead':   {
                    default: 'Error while linking contact to lead: ##1',
                    type: 'textarea', details: '##1 = Error details, if any'
                },
                'qr-code-scanner/new-lead-updated-with-contact':   {
                    default: 'Created lead ##1 and updated with contact "##2"',
                    type: 'textarea', details: '##1 = New lead name, ##2 = Contact name'
                },
                'qr-code-scanner/new-lead-no-contact':   {
                    default: 'Created lead ##1 (no contact added)',
                    type: 'textarea', details: '##1 = New lead name'
                },
                'qr-code-scanner/error-while-creating-new-lead':   {
                    default: 'Error while creating new lead: ##1',
                    type: 'textarea', details: '##1 = Error details, if any'
                },

            },

            // Additional details for categories
            categoryDetails: {
                'sidebar': 'Sidebar link texts',
                'manage-leads-excel-export': 'Manage leads Excel export dialog',
                'manage-leads-bulk-manage': 'Manage leads Bulk assign / delete dialog',
                'manage-leads-bulk-edit': 'Manage leads Bulk Edit dialog',
                'manage-leads-table-settings': 'Manage leads Table Settings dialog',
            }
        }
    },


    getters: {
        ...crudFactory.getters(localizations, localizationsMap),

        activeLocalization() { return this.computed.find(l => l.active === true)},

        activeLocalizationCode() { return this.activeLocalization?.code || 'en' },

        activeDateTimeLocalizationCode() {
            const code = this.activeLocalizationCode

            // Overrides
            if(code === 'en') return 'en-gb'

            return code
        },


        /*
         * Fetch a localized string from currently active localization set.
         *
         * Supports param replacement with ##N, where ##1 will be replace by the first
         * param after the field name (2nd parm to the function) and so on.
         */
        l: (state) => (field, ...args) => {
            if(!(field in state.activeLocalization.fields)) return '<unknown localization field>'

            let text = state.activeLocalization.fields[field]

            for(const [idx, arg] of args.entries()) {
                text = text.replace(`##${idx + 1}`, args[idx])
            }

            return text.trim()
        },


        /*
         * Extends above l() method with support for bold text with asterisks (*bold text*)
         * and newlines.
         *
         * Also turns <tag> arrows into &lt; and &gt; so the return value can safely be used
         * in v-html
         */
        l_escaped: (state) => (field, ...args) => {
            if(!(field in state.activeLocalization.fields)) return '<unknown localization field>'

            let text = state.l(field, ...args)

            // Don't allow html tags directly (using v-html)
            text = text.replaceAll('<', '&lt;')
            text = text.replaceAll('>', '&gt;')

            // Turn newlines into <br>
            text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')

            // Allow bold text with asterisks
            const regex = /\*([^*]+)\*/g
            return text.replace(regex, '<strong>$1</strong>').trim()
        },


        computed() {
            const defaultFields = {}

            for(const [field, value] of Object.entries(this.fields)) {
                defaultFields[field] = value.default
            }

            // Grab all localizations from DB
            const localizations = this.all.map(localization => {
                const language = languageList.find(languageEntry => languageEntry.code === localization.code)

                if(!language) return null

                const mergedFields = Object.assign({}, defaultFields, localization?.fields || {})

                return {
                  _id:      localization._id,
                  active:   localization.active,
                  fullName: `${language.name} (${language.nativeName})`,
                  fields:   mergedFields,
                  ...language
                }
            }).filter(l => l !== null)

            // If there's no English localization saved in DB, add it manually
            const englishLocalization = localizations.find(l => l.code === 'en')

            if(!englishLocalization) {
                const englishLanguage = languageList.find(l => l.code === 'en')
                localizations.push({
                    _id:        'english_not_saved',
                    fullName:   `${englishLanguage.name} (${englishLanguage.nativeName})`,
                    fields:     defaultFields,
                    ...englishLanguage
                })
            }

            // If there's no active localization in DB, mark English as active
            const activeLocalization = localizations.find(l => l.active === true)

            if(!activeLocalization) {
                const englishLocalization = localizations.find(l => l.code === 'en')

                englishLocalization.active = true
            }

            return localizations.sort((a, b) => {
                return a.fullName.localeCompare(b.fullName)
            })
        },
    },


    actions: {
        ...crudFactory.actions('localizations', {}, localizations, localizationsMap),


        async setAsActive(localizationId) {
            let path = api.setAsActive[1] + `/${localizationId}`

            await apiRequest(api.setAsActive[0], path)

            this.syncActive(localizationId)
        },

        async syncActive(localizationId) {
            for(const localization of localizations) {
                if(localization._id === localizationId) {
                    localization.active = true
                } else {
                    localization.active = false
                }
            }

            await this.setDayjsLocale(localizationId)

            if(this.reactivityEnabled) this.updateToggler++
        },


        async setDayjsLocale(localizationId) {
            const localization = this.computed.find(l => l._id === localizationId)

            let code = localization?.code

            if(code === 'en') code = 'en-gb'

            try {
                await import(`dayjs/locale/${code}.js`)

                dayjs.locale(code)
            } catch(error) {
                if(process.env.NODE_ENV !== 'production') {
                    console.error(`localizations/setDayjsLocale: No dayjs locale found for current active localization code "${code}", using "en-gb" instead.`)
                }
                dayjs.locale('en-gb')
            }
        },


        async import(payload) {
            const result = await apiRequest(...api.import, payload)

            if(result.localization) {
                this.PUT(result.localization)
            }

            return result
        },
    }

})
