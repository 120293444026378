import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "manage-leads-mobile-container" }
const _hoisted_2 = { class: "leads-table" }
const _hoisted_3 = { class: "leads-table-header" }
const _hoisted_4 = { class: "leads-table-header-top" }
const _hoisted_5 = { class: "leads-table-title rtitle" }
const _hoisted_6 = { class: "leads-sort" }
const _hoisted_7 = { class: "leads-filter" }
const _hoisted_8 = {
  class: "leads-table-content",
  id: "leads-table-content-handle"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "row-content" }
const _hoisted_13 = { class: "is-flex" }
const _hoisted_14 = { style: {"margin-right":"5px"} }
const _hoisted_15 = { class: "leads-table-footer" }
const _hoisted_16 = {
  key: 0,
  class: "rpagination"
}
const _hoisted_17 = { class: "pagination-per-page" }
const _hoisted_18 = { class: "leads-per-page" }
const _hoisted_19 = { class: "leads-per-page-dropdown" }
const _hoisted_20 = { class: "bold" }
const _hoisted_21 = { class: "pagination-pages" }
const _hoisted_22 = { class: "pagination-position" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_columns_sorting = _resolveComponent("columns-sorting")
  const _component_columns_quicksearch = _resolveComponent("columns-quicksearch")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.leads.length) + " " + _toDisplayString(_ctx.l('manage-leads-table/num-leads')), 1 /* TEXT */),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_columns_sorting, {
              column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") },
              onSortingChanged: _cache[0] || (_cache[0] = $event => ($options.scrollPageToTop()))
            }, null, 8 /* PROPS */, ["column"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_columns_quicksearch, {
            column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") }
          }, null, 8 /* PROPS */, ["column"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedLeads, (lead) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "lead-row",
            key: "lead_" + lead._id
          }, [
            _createElementVNode("div", {
              class: "main-lead-row",
              onClick: $event => ($options.goToLead(lead))
            }, [
              ($options.leadHasSubleads(lead))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "row-collapse",
                    onClick: _withModifiers($event => ($options.toggleSubLeadsCollapse(lead)), ["stop"])
                  }, [
                    _createVNode(_component_b_icon, {
                      icon: this.collapsedMainLeads.has(lead._id) ? "chevron-up" : "chevron-right",
                      "custom-size": "mdi-24px",
                      "custom-class": "gray-icon"
                    }, null, 8 /* PROPS */, ["icon"])
                  ], 8 /* PROPS */, _hoisted_10))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", {
                class: _normalizeClass(["row-content", { "with-collapse": $options.leadHasSubleads(lead) }])
              }, _toDisplayString(lead.name), 3 /* TEXT, CLASS */)
            ], 8 /* PROPS */, _hoisted_9),
            (_ctx.collapsedMainLeads.has(lead._id))
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(lead.subleads, (sublead) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "sub-lead-row",
                    key: sublead._id,
                    onClick: $event => ($options.goToLead(sublead))
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_b_icon, { icon: "arrow-right-thin" })
                        ]),
                        _createElementVNode("div", null, _toDisplayString(sublead.name), 1 /* TEXT */)
                      ])
                    ])
                  ], 8 /* PROPS */, _hoisted_11))
                }), 128 /* KEYED_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createElementVNode("div", _hoisted_15, [
        (!_ctx.appLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_b_dropdown, {
                      modelValue: _ctx.leadsPerPage,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.leadsPerPage) = $event)),
                      "aria-role": "list",
                      onChange: _cache[2] || (_cache[2] = (value) => $options.leadsPerPageChanged(value)),
                      "mobile-modal": false,
                      position: "is-top-right"
                    }, {
                      trigger: _withCtx(({ active }) => [
                        _createVNode(_component_b_field, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_button, {
                              label: _ctx.leadsPerPage,
                              type: "is-select",
                              expanded: ""
                            }, null, 8 /* PROPS */, ["label"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_b_dropdown_item, { custom: "" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.l('manage-leads-table/leads-per-page')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "10"
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("10")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "25"
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("25")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "50"
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("50")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "100"
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("100")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "250"
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("250")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "500"
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("500")
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "1000"
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode("1000")
                          ])),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", {
                  class: "pagination-button noselect",
                  onClick: _cache[3] || (_cache[3] = $event => ($options.paginationFirstPage()))
                }, [
                  _createVNode(_component_b_icon, {
                    icon: "page-first",
                    "custom-size": "mdi-24px"
                  })
                ]),
                _createElementVNode("div", {
                  class: "pagination-button noselect",
                  onClick: _cache[4] || (_cache[4] = $event => ($options.paginationPrevPage()))
                }, [
                  _createVNode(_component_b_icon, {
                    icon: "chevron-left",
                    "custom-size": "mdi-24px"
                  })
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_b_input, {
                    id: "leadsPerPageInput",
                    type: "number",
                    size: "is-small",
                    min: "1",
                    modelValue: _ctx.currentPage,
                    onInput: _cache[5] || (_cache[5] = (event) => { $options.setPage(event.target.value) }),
                    style: {"width":"1ch"}
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _cache[16] || (_cache[16] = _createElementVNode("div", null, "/", -1 /* HOISTED */)),
                  _createElementVNode("div", null, _toDisplayString(_ctx.pagination?.lastPage || 1), 1 /* TEXT */)
                ]),
                _createElementVNode("div", {
                  class: "pagination-button noselect",
                  onClick: _cache[6] || (_cache[6] = $event => ($options.paginationNextPage()))
                }, [
                  _createVNode(_component_b_icon, {
                    icon: "chevron-right",
                    "custom-size": "mdi-24px"
                  })
                ]),
                _createElementVNode("div", {
                  class: "pagination-button noselect",
                  onClick: _cache[7] || (_cache[7] = $event => ($options.paginationLastPage()))
                }, [
                  _createVNode(_component_b_icon, {
                    icon: "page-last",
                    "custom-size": "mdi-24px"
                  })
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.appLoading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}