import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"display":"contents"} }
const _hoisted_2 = {
  key: 0,
  style: {"display":"contents"}
}
const _hoisted_3 = { class: "page-layout" }
const _hoisted_4 = {
  class: "page-content",
  id: "pageContent"
}
const _hoisted_5 = {
  key: 1,
  style: {"display":"contents"}
}
const _hoisted_6 = { class: "mobile-layout" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_desktop_top_nav = _resolveComponent("desktop-top-nav")
  const _component_desktop_sidebar = _resolveComponent("desktop-sidebar")
  const _component_mobile_top = _resolveComponent("mobile-top")
  const _component_mobile_bottom = _resolveComponent("mobile-bottom")
  const _component_login = _resolveComponent("login")
  const _component_router_view = _resolveComponent("router-view")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        (!_ctx.isMobile && route.name !== "login")
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createCommentVNode(""),
              _createCommentVNode(" DESKTOP layout"),
              _createCommentVNode(""),
              _createVNode(_component_desktop_top_nav),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_desktop_sidebar),
                _createElementVNode("section", _hoisted_4, [
                  _createCommentVNode(" Our keep-alive pages"),
                  (_openBlock(), _createBlock(_KeepAlive, { include: [ "ManageLeads", "Activities", "Overview" ] }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1024 /* DYNAMIC_SLOTS */))
                ])
              ])
            ]))
          : (_ctx.isMobile && route.name !== "login")
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createCommentVNode(""),
                _createCommentVNode(" MOBILE layout"),
                _createCommentVNode(""),
                _createVNode(_component_mobile_top),
                _createElementVNode("div", _hoisted_6, [
                  _createCommentVNode(" Our keep-alive pages"),
                  (_openBlock(), _createBlock(_KeepAlive, { include: [ "MapView" ] }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ], 1024 /* DYNAMIC_SLOTS */))
                ]),
                _createVNode(_component_mobile_bottom)
              ]))
            : (route.name === "login")
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createCommentVNode(" Login page, desktop/mobile"),
                  _createVNode(_component_login)
                ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
              : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_loading, {
      "is-full-page": true,
      modelValue: _ctx.rootStore.appAuthenticating,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.rootStore.appAuthenticating) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_b_loading, {
      "is-full-page": true,
      modelValue: $data.localizationsLoading,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.localizationsLoading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}